// extracted by mini-css-extract-plugin
export var button = "DashboardCard-module--button--51b44";
export var container = "DashboardCard-module--container--2b557";
export var enabled = "DashboardCard-module--enabled--efa64";
export var enabledIndicator = "DashboardCard-module--enabledIndicator--5809e";
export var headerContainer = "DashboardCard-module--headerContainer--1161c";
export var tagsList = "DashboardCard-module--tagsList--74a83";
export var title = "DashboardCard-module--title--8b617";
export var titleContainer = "DashboardCard-module--titleContainer--fb75b";
export var typeContainer = "DashboardCard-module--typeContainer--129fc";
export var typeLabel = "DashboardCard-module--typeLabel--eb23c";